import React, {Component} from 'react';
import { connect } from 'react-redux'

// import './lib/layui/css/layui.css'


import './App0.less'
import SideItem from "./component/SideItem";
import { get_navigate_data, search_content, get_csrf_token, get_article_by_key } from './api/api'

let Python = {name: 'Python', key: 1, parent_id: null, children: []}
let Java = {name: 'Java', key: 2, parent_id: null, children: []}
let JS = {name: 'JS', key: 3, parent_id: null, children: []}
let Linux = {name: 'Linux', key: 4, parent_id: null, children: []}
let DataBase = {name: 'DataBase', key: 5, parent_id: null, children: []}

class App extends Component {

    code_style_regex = /<pre>/g
    code_style_replace_str = '<pre class="layui-code">'

    constructor(props) {
        super(props);

        this.state = {
            // 大类(语言) -- 所有的一级导航标题
            navigate_items: ['Python', 'Java', 'JS', 'Linux', 'DataBase'],
            // 大类(语言) -- 一级导航标题 -- 当前选中项
            current_nav: 'Python',
            // 查询内容 -- 查询范围类型
            search_type: '0',
            // 查询内容 -- 查询内容
            search_content: '',
            // 二级标题集合
            side_dict: { 'Python': Python, 'Java': Java, 'JS': JS, 'Linux': Linux, 'DataBase': DataBase },
            // 文章内容
            article_content_obj: {},
            //
            current_article_nav: '',
            
        };

        this.change_current_nav = this.change_current_nav.bind(this)
        this.change_search_type = this.change_search_type.bind(this)
        this.change_search_content = this.change_search_content.bind(this)
        this.send_search_request = this.send_search_request.bind(this)
        this.render_left_side_content = this.render_left_side_content.bind(this)
        this.update_article = this.update_article.bind(this)
    }

    // 改变当前选择大类
    change_current_nav(new_nav){
        this.setState({current_nav: new_nav})
    }
    // 改变当前查询类型
    change_search_type(e){
        this.setState({search_type: e.target.value})
    }
    // 改变当前查询内容
    change_search_content(e){
        this.setState({search_content: e.target.value})
    }
    // 发送查询请求
    send_search_request(e){
        // 如果按下不是回车键, 不作处理
        if (e.key !== 'Enter'){
            return
        }
        search_content(this.state.search_type, this.state.search_content)
        .then(search_resp=>{
            console.log('');
            console.log('search_resp');
            console.log(search_resp.data.data);
            
        })
        .catch(err=>{
            console.log('')
            console.log('search_content_err')
            console.log(err)
        })
    }
    // 根据当前选择的类型, 左边导航条渲染不同内容
    render_left_side_content(){
        let res_data = this.state.side_dict[this.state.current_nav]
        let content_item_arr = [
            this.state.navigate_items.map(obj=>{
                return this.state.side_dict[obj]
            })
        ]
        return <SideItem content_item_obj={res_data}
                         parent={this}
        />
    }
    // 发情请求到后端, 更新页面内容
    update_article(article_key){
        get_article_by_key(article_key)
            .then(articel_resp => {
		let new_data = {...articel_resp.data}
		let new_articel = new_data.content.replace(this.code_style_regex, this.code_style_replace_str)
		new_data['content'] = new_articel
                this.setState({article_content_obj: new_data})
            })
            .catch(err => {
                console.log('');
                console.log('get article err');
                console.log(err);
            })


    }

    componentDidMount() {
        get_csrf_token()
            .then(get_token_resp => {
                // console.log('');
                // console.log('get_token_resp');
                // console.log(get_token_resp);
                sessionStorage.csrf_token = get_token_resp.data.csrf_token
            })
            .catch(err => {
                console.log('');
                console.log('get csrf token err');
                console.log(err);
            })
        let _this = this
        get_navigate_data()
            .then(resp_res => {
                _this.setState({ navigate_items: resp_res.data.navigate_items })
                _this.setState({ side_dict: resp_res.data.the_category_dict })
            })
            .catch(err => {
                console.log('navigate请求失败');
                console.log(err);
            })



    }


/*

    componentDidUpdate(prevProps, prevState, snapshot) {
        let $ = new window.$
	console.log('Didupdate')
	console.log($.find('body'))
	let pre_arr = $.find('pre')
    }

*/

    render() {

        return (
            // 最外面的盒子
            <div className="layui-layout-body my-container">
                {/* 中间盒子 */}
                <div className="layui-layout layui-layout-admin">

                    {/* 第一行标题盒子*/}
                    <div className="layui-header">
                        {/* 第一行标题盒子 -- 1 -- logo */}
                        <div className="layui-logo">
                            logo
                        </div>
                        {/* 第一行标题盒子 -- 2 -- 大类明细(可配合layui已有的水平导航) */}
                        <ul className="layui-nav layui-layout-left">
                            {
                                this.state.navigate_items.map(nav => {
                                    return (
                                        <li className='layui-nav-item' key={nav} onClick={()=>{this.change_current_nav(nav)}}>
                                            <button type="button" className={`${this.state.current_nav !== nav ? 'layui-btn' : 'layui-btn layui-btn-my_choice'}`}>{nav}</button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        {/* 第一行标题盒子 -- 3 -- 查询 */}
                        <ul className="layui-nav layui-layout-right">

                            {/* 第一行标题盒子 -- 3 -- 查询 -- 查询类型 */}
                            <li className="layui-nav-item">

                                <div style={{textAlign: 'center'}}>

                                    <select className='search-type' name="city" lay-verify=""
                                            value={this.state.search_type}
                                            onChange={this.change_search_type}
                                    >
                                        <option value="0">全部</option>
                                        <option value="1">标题</option>
                                        <option value="2">关键字</option>
                                        <option value="3">正文</option>
                                        <option value="4" disabled>评论</option>
                                    </select>
                                </div>
                            </li>
                            {/* 第一行标题盒子 -- 3 -- 查询 -- 查询类型 */}
                            <li className="layui-nav-item">
                                <input type="text" placeholder="请输入查询内容" autoComplete="off"
                                       className="layui-input" style={ {color: '#000'} }
                                       value={this.state.search_content}
                                       onChange={(e)=>{this.change_search_content(e)}}
                                       onKeyDown={(e)=>{this.send_search_request(e)}}
                                />
                            </li>

                        </ul>
                    </div>

                    {/* 左边导航条 -- 大盒子 */}
                    <div className="layui-side layui-bg-black">
                        {/* 左边导航条 -- 中间盒子 */}
                        <div className="layui-side-scroll">

                            {/* 左侧导航区域（可配合layui已有的垂直导航） */}
                            {/*<ul className="layui-nav layui-nav-tree" lay-filter="test">*/}
                            {/*    <li className="layui-nav-item layui-nav-itemed">*/}
                            {/*        <a className="" href="#">所有商品</a>*/}
                            {/*        <dl className="layui-nav-child">*/}
                            {/*            <dd><a href="#">列表一</a></dd>*/}
                            {/*            <dd><a href="#">列表二</a></dd>*/}
                            {/*            <dd><a href="#">列表三</a></dd>*/}
                            {/*            <dd><a href="">超链接</a></dd>*/}
                            {/*        </dl>*/}
                            {/*    </li>*/}
                            {/*    <li className="layui-nav-item">*/}
                            {/*        <a href="#">解决方案</a>*/}
                            {/*        <dl className="layui-nav-child">*/}
                            {/*            <dd><a href="#">列表一</a></dd>*/}
                            {/*            <dd><a href="#">列表二</a></dd>*/}
                            {/*            <dd><a href="">超链接</a></dd>*/}
                            {/*        </dl>*/}
                            {/*    </li>*/}
                            {/*    <li className="layui-nav-item"><a href="">云市场</a></li>*/}
                            {/*    <li className="layui-nav-item"><a href="">发布商品</a></li>*/}
                            {/*</ul>*/}

                            {
                                this.render_left_side_content()
                            }







                        </div>
                    </div>

                    {/* 正文 -- 滚动div */}
                    <div className="layui-body" style={ {padding: '0 4rem', backgroundColor: '#fbeed3'} }>
                        {/* 正文 -- 上方面包屑 */}
                        {
                            this.state.current_article_nav
                                ?
                            <ul className="breadcrumb my-crumbs" style={ {backgroundColor: '#fbeed3'} }>
                            <li><a href="#">{this.state.current_article_nav}</a></li>
                            <li><a href="#">{this.props.change_current_side}</a></li>
                            <li className="active">{this.props.change_current_article}</li>
                            </ul>
                                :
                                ''
                        }

                        {/* 内容主体区域 */}
                        <div>
                            <h1 style={{ textAlign: 'center', marginBottom: '2.5rem' }}>{this.state.article_content_obj.title}</h1>
                            <h3 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>{this.state.article_content_obj.key_words}</h3>
                            <div dangerouslySetInnerHTML= {{ __html: this.state.article_content_obj.content }} />
                            {/* <hr />
                            <hr />
                            <hr />
                            <hr />
                            <hr />

                            1、依赖库安装
                            <pre className="layui-code">
                                yum -y groupinstall "Development Tools"<br/>
                                yum -y install zlib-devel libffi-devel openssl openssl-devel gdbm gdbm-devel gcc gcc-c++ bzip2 bzip2-devel lzma xz-devel xz-compat-libs ncurses ncurses-devel sqlite sqlite-devel readline readline-devel tk tk-devel<br/>
                            </pre>

                            2、下载源码
                            <pre className="layui-code">
                                wget https://www.python.org/ftp/python/3.7.6/Python-3.7.6.tar.xz
                            </pre>

                            3、解压源码包
                            <pre className="layui-code">
                                tar -xf Python-3.7.6.tar.xz
                            </pre>

                            4、执行编译配置
                            <pre className="layui-code">
                                ./configure --prefix=/usr/local/python-3.7.6/
                            </pre>

                            5、编译安装
                            <pre className="layui-code">
                                make && make install
                            </pre>

                            6、配置环境变量
                            <pre className="layui-code">
                                echo 'export PYTHON3_PATH=/usr/local/python-3.7.6/' >> /etc/profile <br/>
                                echo 'export PATH=$PYTHON3_PATH/bin:$PATH' >> /etc/profile <br/>
                                source /etc/profile
                            </pre>

                            7、配置pip镜像源
                            <pre className="layui-code">
                            # 在当前用户的家目录下新建一个隐藏的目录.pip, 创建配置文件pip.conf <br/>
                            cd <br/>
                            mkdir .pip <br/>
                            echo '[global]' > ./.pip/pip.conf <br/>
                            echo 'index-url=https://mirrors.aliyun.com/pypi/simple' >> ./.pip/pip.conf <br/>
                            echo '[install]' >> ./.pip/pip.conf <br/>
                            echo 'trusted-host=mirrors.aliyun.com' >> ./.pip/pip.conf
                            </pre>

                            <br/><br/>
                            ************************************************************<br/><br/>

                            Linux(CentOS) yum 源修改<br/><br/>

                            1) 安装wget
                            <pre className="layui-code">
                                yum install -y wget
                            </pre>

                            2) 下载CentOS 7的repo文件
                            <pre className="layui-code">
                                wget -O /etc/yum.repos.d/CentOS-Base.repo http://mirrors.aliyun.com/repo/Centos-7.repo
                            </pre>

                            3) 更新镜像源
                            <pre className="layui-code">
                                # 清除缓存 <br/>
                                yum clean all <br/><br/>

                                # 生成缓存<br/>
                                yum makecache<br/>
                            </pre>

                            按照官方(https://developer.aliyun.com/mirror/)操作 : https://developer.aliyun.com/mirror/centos?spm=a2c6h.13651102.0.0.3e221b11DYeYFI<br/><br/>

                            * 参考 https://developer.aliyun.com/article/704987<br/><br/>

                            ************************************************************<br/><br/>
                         */}
                         </div>

                        {/* <code>&lt;header&gt;</code> */}

                        {/* <pre className="pre-scrollable">

                            String str = 'abc';
                            Map map = new HashMap(10);
                            map.push(str, 1);


                        </pre> */}

                        
                    </div>

                    {/* 底部 -- 固定div */}
                    <div className="layui-footer" style={ {backgroundColor: '#fbeed3', display: 'flex', alignItems: 'center', justifyContent: 'center'} }>
                        <div>上一篇</div>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <div>下一篇</div>
                    </div>
                </div>
            </div>
        );
    }
}

App.propTypes = {};

function filter(state){
    return state
}

export default connect(filter)(App);


