import React, {Component} from 'react';
import { PropTypes } from 'prop-types'


import {change_side, change_article} from "../store/action";


// side侧栏中渲染的数据
class SideItem extends Component {

    /*
    *  description:
    *       渲染侧栏 side 中的所有列表数据
    *
    *  // @param current_side: 当前选中的side (展示的文字)
    *  // @param current_article: 当前选中的文章
    *  @param content_item_obj: 当前选中的nav(大类 - 语言)对应的所有 side 和 article 的集合
    *  // @param parent: 调用当前组件的父组件
    *  e.g.:
    *  content_item_obj = [
                            {
                                name: 'Python基础',
                                children: [
                                    'Python语法',
                                    'Python变量',
                                    'Python分支循环',
                                    'Python逻辑判断',
                                ]
                            },
                            {
                                name: 'Python中级',
                                children: [
                                    'Python类',
                                    'Python函数',
                                    'Python装饰器',
                                    'Python包',
                                ]
                            }
                          ]
    */

    constructor(props) {
        super(props);

        this.state = {
            current_side: '',
            current_article: '',
        };
        this.change_article = this.change_article.bind(this)
    }

    // 修改当前选择的article
    change_article(current_article_nav, new_side, new_article, article_key){
        this.setState({current_side: new_side})
        this.setState({current_article: new_article})
        this.setState({current_article_nav: current_article_nav})
        this.props.parent.props.dispatch(change_side(new_side))
        this.props.parent.props.dispatch(change_article(new_article))
        // 发送ajax请求, 获取对应数据
        this.props.parent.update_article(article_key)
        
    }

    render() {

        let child_id_arr = []
        let parent_div_key = Math.round(Math.random() * 100) + '_' + this.props.content_item_obj.name
        let current_article_nav = this.props.content_item_obj.name

        this.props.content_item_obj.children.map(each_child => {
            var suffix_key = Math.round(Math.random() * 100) + '_' + each_child.name
            var child_id = `child_id_${suffix_key}`
            child_id_arr.push(child_id)
        })

        return (
            <div className="panel-group" id={parent_div_key}>
                {
                    this.props.content_item_obj.children.map((side_obj, ind) => {
                        // if (side_obj.children == 'undefined' || side_obj.children.length === 0){
                        //     return
                        // }
                        return (
                            <div className="panel panel-default" key={side_obj.name}>
                                <div id="header1" data-toggle="collapse"
                                     className={`panel-heading ${this.state.current_side === side_obj.name ? 'choice_second' : ''}`}
                                     data-target={`#${child_id_arr[ind]}`}
                                     data-parent={`#${parent_div_key}`}
                                >
                                    <i className={`glyphicon glyphicon-list-alt`}/>&emsp;
                                    <a className='side-title' href="#">{side_obj.name}</a>
                                </div>
                                <div id={child_id_arr[ind]} className="collapse panel-collapse">
                                    <div className="panel-body">
                                        <ul className="nav my-left-side-ul">
                                            {
                                                side_obj.children.map(article => {
                                                    return (
                                                        <li key={article.key} onClick={ ()=>{this.change_article(current_article_nav, side_obj.name, article.name, article.key)} }>
                                                            <a href="#" title={article.name} className={`${this.state.current_article === article.name ? 'choice_article' : ''}`}>
                                                                {article.name.length <= 15 ? article.name : article.name.substr(0, 14) + '...'}
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }



            </div>
        );
    }
}

SideItem.propTypes = {
    content_item_obj : PropTypes.object,
};

SideItem.defaultProps = {
    content_item_obj : {
        children: [
            {
                children: [
                    {
                        children: null,
                        key: 3,
                        name: 'Python语法',
                        parent_id: 2
                    },
                    {
                        children: null,
                        key: 3,
                        name: 'Python变量',
                        parent_id: 2
                    }
                ],
                key: 2,
                name: 'Python基础',
                parent_id: 1
            },
            {
                children: [
                    {
                        children: null,
                        key: 3,
                        name: 'Python类',
                        parent_id: 3
                    },
                    {
                        children: null,
                        key: 3,
                        name: 'Python包',
                        parent_id: 3
                    }
                ],
                key: 3,
                name: 'Python中级',
                parent_id: 1
            }
        ],
        key: 1,
        name: 'Python',
        parent_id: null
    }
};


export default SideItem;