import { combineReducers } from 'redux'


function change_current_side(state= null, action){
    switch (action.type){
        case 'change_side':
            return action.current_side
        case 'get_current_side':
            return state
        default:
            return state
    }
}

function change_current_article(state= null, action){
    switch (action.type){
        case 'change_article':
            return action.current_article
        case 'get_current_article':
            return state
        default:
            return state
    }
}


export default combineReducers({
    change_current_side,
    change_current_article,
})
