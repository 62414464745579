import axios from 'axios'

const IP = 'http://www.fanqingshan.com:8000'

function get_csrf_token(){
    return axios.get('/van/token')
}

function get_navigate_data(){
    return axios.get(`/van/navigate`)
}

function search_content(type, content){
    console.log(sessionStorage.csrf_token);
    console.log('csrf_token');

    let fm_data = new FormData()
    fm_data.append('content', content)
    fm_data.append('type', type)
    return axios.post('/van/search', 
        fm_data, 
        {
            headers: {'X-CSRFToken': sessionStorage.csrf_token}
        })
}

function get_article_by_key(article_key){
    return axios.get(`/van/article?key=${article_key}`)
}

export {
    get_navigate_data,
    search_content,
    get_csrf_token,
    get_article_by_key
}


