
function get_current_side(){
    return {
        'type': 'get_current_side',
    }
}
function change_side(new_side=''){
    return {
        'type': 'change_side',
        'current_side': new_side,
    }
}

function get_current_article(){
    return {
        'type': 'get_current_article',
    }
}
function change_article(new_article= ''){
    return {
        'type': 'change_article',
        'current_article': new_article,
    }
}

export {
    get_current_side, change_side,
    get_current_article, change_article
}